import React, { useState } from "react";
import { navigate, Link } from "gatsby";
import { useFormik } from "formik";

import firebase from "./../utilities/firebase.js";
import Header from "../components/header";

const Login = () => {
  const [loginError, setLoginError] = useState("");
  const [isLoggingIn, setIsLoggingIn] = useState(false);

  const validate = (values) => {
    console.log(values);
    console.log("first name: ", values.firstName);
    const errors = {};

    if (!values.email) {
      errors.email = "Required";
    } else if (
      !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)
    ) {
      errors.email = "Invalid email address";
    }

    if (!values.password) {
      errors.password = "Required";
    } else if (values.password.length < 8) {
      errors.password = "Must be 8 characters or more";
    }

    console.log(errors);
    return errors;
  };

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validate,
    onSubmit: (values) => {
      console.log(values.brand);
      setLoginError("");
      setIsLoggingIn(true);

      firebase
        .auth()
        .signInWithEmailAndPassword(values.email, values.password)
        .then((userCredential) => {
          console.log("userCredential: ", userCredential);
          // Signed in
          navigate("/app/dashboard");
        })
        .catch((error) => {
          //var errorCode = error.code;
          //var errorMessage = error.message;
          setIsLoggingIn(false);
          console.log(error.code, error.message);
          console.log(error);
          console.log(error.code === "auth/wrong-password");
          if (
            error.code === "auth/wrong-password" ||
            error.code === "auth/user-not-found"
          ) {
            console.log("inne");
            setLoginError("Wrong username or password");
          }
        });
    },
  });

  //auth.signOut()
  console.log(loginError);

  return !isLoggingIn ? (
    <div className="min-h-screen bg-gray-50">
      <Header />
      <div className="h-full flex flex-col justify-center lg:py-0 lg:pt-20">
        <div>
          <div className="cardContainerOuter max-w-xl mx-auto">
            <div className="cardContainerInner bg-gray-50">
              <div className="text-center mb-16">
                <h2>Log in</h2>
              </div>
              <form className="space-y-6" onSubmit={formik.handleSubmit}>
                <div>
                  <label htmlFor="email">Email address</label>
                  <div className="mt-1">
                    <input
                      id="email"
                      name="email"
                      type="email"
                      autoComplete="email"
                      required
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.email}
                      className={`${formik.errors.email && formik.touched.email
                        ? "border-red-800"
                        : "border-gray-300"
                        } appearance-none block w-full px-3 py-2 border rounded-md placeholder-gray-400 focus:outline-none focus:ring-black focus:border-black sm:text-base`}
                    />
                    {formik.errors.email && formik.touched.email && (
                      <span className="inline-flex items-center px-2 py-0.5 mt-2 rounded text-xs font-medium bg-red-100 text-red-800">
                        {formik.errors.email}
                      </span>
                    )}
                  </div>
                </div>
                <div>
                  <label htmlFor="password">Password</label>
                  <div className="mt-1">
                    <input
                      id="password"
                      name="password"
                      type="password"
                      autoComplete="current-password"
                      required
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.password}
                      className={`${formik.errors.password && formik.touched.password
                        ? "border-red-800"
                        : "border-gray-300"
                        } appearance-none block w-full px-3 py-2 border rounded-md placeholder-gray-400 focus:outline-none focus:ring-black focus:border-black sm:text-base`}
                    />
                    {formik.errors.password && formik.touched.password && (
                      <span className="inline-flex items-center px-2 py-0.5 mt-2 rounded text-xs font-medium bg-red-100 text-red-800">
                        {formik.errors.password}
                      </span>
                    )}
                    {loginError && (
                      <span className="inline-flex items-center px-2 py-0.5 mt-2 rounded text-xs font-medium bg-red-100 text-red-800">
                        {loginError}
                      </span>
                    )}
                  </div>
                </div>
                <div className="text-right">
                  <div className="text-base">
                    <Link
                      to="/reset-password"
                      className="font-medium text-coral-500 hover:text-coral-600"
                    >
                      Forgot your password?
                    </Link>
                  </div>
                </div>
                <div className="text-right">
                  <button type="submit" className="primary-button">
                    Log in
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  ) : (
    <div className="flex justify-center items-center h-screen">
      <svg
        className="animate-spin h-5 w-5 flex justify-center align-middle text-black"
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
      >
        <circle
          className="opacity-25"
          cx="12"
          cy="12"
          r="10"
          stroke="currentColor"
          strokeWidth="4"
        ></circle>
        <path
          className="opacity-75"
          fill="currentColor"
          d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
        ></path>
      </svg>
      {/*<p className="ml-2">Loading...</p>*/}
    </div>
  );
};

export default Login;
